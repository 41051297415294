export {DATA_STORAGE, getDataStorage} from './DataStorage';
export {getResourceDescriptor} from './getResourceDescriptor';
export type {Resource} from './getResourceDescriptor';
export type {PickResourceResponseType} from './PickResourceResponseType';
export {useFeature} from './useFeature';
export type {FeatureClassType} from './useFeature';
export {
  useResource,
  useResources,
  setupGetNavString,
  setupRefreshOnVisible,
  setupUseOnVisible,
} from './useResources';
export {createResource, createStaticResource} from './createResource';
export type {ResourceInstance} from './createResource';
export {createFeature} from './createFeature';
