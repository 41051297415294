import {
  VerificationItemStatus,
  VerificationItemStatusesNegative,
} from './VerificationResponse';

export const isNegativeVerificationStatus = (
  status: VerificationItemStatus | undefined
) => {
  if (!status) {
    return false;
  }
  return VerificationItemStatusesNegative.includes(status);
};
